$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';.container {
  position: relative;
}

.button {
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
}
