$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';

.dropdown {
  @media #{$fromLaptop} {
    z-index: var(--b-z-index-2);
    top: var(--b-space-xs);
    right: #{toRem(0)};
    left: unset;

    width: #{toRem(250)};
    max-height: #{toRem(600)};
    padding: unset;

    overflow-y: auto;

    [dir='rtl'] & {
      right: unset;
      left: #{toRem(0)};
    }
  }
}

.container {
  @media #{$fromLaptop} {
    padding: var(--b-space-m) 0;
  }
}

.button {
  @media #{$fromLaptop} {
    width: 100%;
    padding: var(--b-space-s) var(--b-space-xl);

    text-align: initial;

    [dir='rtl'] & {
      padding: var(--b-space-s) var(--b-space-xl) var(--b-space-s) 0;
    }

    &:hover {
      background-color: var(--b-color-fill-ocean-10);
      cursor: pointer;
    }
  }
}
