$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/touch';

.container {
  @media #{$fromLaptop} {
    position: relative;
  }
}

.button {
  @include touch-security-zone;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}
