$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';
@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/common';

.container {
  position: sticky;

  // +1 para posicionarse por encima de la cabecera
  z-index: calc(var(--b-z-index-4) + 1);
  top: 0;

  @media #{$fromLaptop} {
    position: fixed;
    right: 0;
    left: 0;
  }
}

.textContainer {
  display: flex;
  align-items: center;

  text-align: center;
  background-color: var(--b-color-ribbon-background);
}

.text {
  flex-grow: 1;

  padding: var(--b-space-xs);
}

.closeButton {
  display: inline-block;
  align-self: stretch;

  padding: var(--b-space-xs);

  cursor: pointer;
}
