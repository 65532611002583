$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  width: #{toRem(32)};
  height: #{toRem(32)};
}

.initials {
  line-height: unset;
}
