$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/fonts';

.title {
  margin-bottom: var(--b-space-l);

  @media #{$fromLaptop} {
    margin-bottom: var(--b-space-m);
  }
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.firstElement {
  margin-bottom: var(--b-space-l);
}

.listElement {
  @include font-m-300;
  margin-bottom: var(--b-space-xxs);

  &:before {
    content: '•';

    margin-right: var(--b-space-xs);

    font-size: #{toRem(16)};
    color: var(--b-color-text-dark);

    [dir='rtl'] & {
      margin-left: var(--b-space-xs);
      margin-right: unset;
    }
  }
}
