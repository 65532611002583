$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';.container {
  cursor: pointer;
  text-decoration: underline;
}

.default {
  color: var(--b-color-secondary-link);

  &:hover {
    color: var(--b-color-secondary-link-hover);
  }

  &:active {
    color: var(--b-color-secondary-link-pressed);
  }
}

.thickUnderline {
  position: relative;

  display: inline-block;

  width: fit-content;

  color: var(--b-color-secondary-link);
  text-decoration: none !important;

  &:hover {
    color: var(--b-color-secondary-link-hover);
  }

  &:active {
    color: var(--b-color-secondary-link-pressed);
  }
}

.thickUnderline::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;

  width: 100%;
  height: 2px;

  background-color: var(--b-color-interactive-secondary-enabled);
}

.myBarcelo {
  color: var(--b-color-myBarcelo-link);

  &:hover {
    color: var(--b-color-myBarcelo-link-hover);
  }

  &:active {
    color: var(--b-color-myBarcelo-link-pressed);
  }
}
