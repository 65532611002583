$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.dropdown {
  @media #{$fromLaptop} {
    z-index: var(--b-z-index-2);
    top: var(--b-space-xs);
    right: #{toRem(0)};
    left: unset;

    width: #{toRem(450)};
    padding: unset;

    overflow-y: hidden;

    [dir='rtl'] & {
      right: unset;
      left: #{toRem(0)};
    }
  }
}

.container {
  @media #{$fromLaptop} {
    max-height: #{toRem(600)};
    padding: var(--b-space-m) 0;

    overflow-y: auto;
  }
}

.button {
  @media #{$fromLaptop} {
    width: 100%;
    padding: var(--b-space-s) var(--b-space-xl);

    text-align: initial;

    &:hover {
      background-color: var(--b-color-interactive-primary-hover);
      cursor: pointer;
    }
  }
}
