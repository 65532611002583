$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mediaQueries';

@mixin xsSymbolMargin {
  &.symbol-after {
    margin-left: var(--b-space-xs);
  }

  &.symbol-before {
    margin-right: var(--b-space-xs);
  }
}

@mixin xxsSymbolMargin {
  &.symbol-after {
    margin-left: var(--b-space-xxs);
  }

  &.symbol-before {
    margin-right: var(--b-space-xxs);
  }
}

.container {
  position: relative;

  display: inline;

  width: fit-content;

  /* Esto es necesario para que en el caso de rtl se vea bien el simbolo negativo */
  direction: ltr;
  unicode-bidi: bidi-override;
}

.strikethrough {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;

  width: 100%;
  height: #{toRem(1)};

  background-color: var(--b-color-support-error);
}

.symbol-xs-gap {
  @include xsSymbolMargin;
}

.symbol-xxs-gap {
  @include xxsSymbolMargin;
}

@media #{$onlyMobile} {
  .mobile-symbol-xs-gap {
    @include xsSymbolMargin;
  }
  .mobile-symbol-xxs-gap {
    @include xxsSymbolMargin;
  }
}

@media #{$onlyTablet} {
  .tablet-symbol-xs-gap {
    @include xsSymbolMargin;
  }
  .tablet-symbol-xxs-gap {
    @include xxsSymbolMargin;
  }
}

@media #{$onlyLaptop} {
  .laptop-symbol-xs-gap {
    @include xsSymbolMargin;
  }
  .laptop-symbol-xxs-gap {
    @include xxsSymbolMargin;
  }
}

@media #{$onlyDesktop} {
  .desktop-symbol-xs-gap {
    @include xsSymbolMargin;
  }
  .desktop-symbol-xxs-gap {
    @include xxsSymbolMargin;
  }
}
