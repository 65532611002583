$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/touch';

.modalWrapper {
  @include contentContainer;

  position: fixed;
  z-index: var(--b-z-index-4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  padding-top: #{toRem(72)};

  transition: all 0.3s;

  background-color: var(--b-color-bg-white);
}

.modal {
  display: flex;
  flex-direction: column;

  height: -webkit-fill-available;

  overflow-y: auto;
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  justify-content: flex-end;

  padding: var(--b-space-l) var(--b-space-m);
}

.button {
  @include touch-security-zone;
  display: flex;
  justify-content: center;
  align-items: center;
}
