$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/fonts';

.buttonWrapper {
  display: flex;
  gap: var(--b-space-l);
  flex-direction: column;

  width: 100%;
  padding-bottom: var(--b-space-xl);

  text-align: start;

  @media #{$fromTablet} {
    flex-direction: row;

    padding-bottom: var(--b-space-xs);
  }
}
