$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';

.dropdown {
  z-index: var(--b-z-index-3);
  top: #{toRem(24)};
  right: #{toRem(16)};

  width: #{toRem(200)};
  padding: unset;

  [dir='rtl'] & {
    right: unset;
    left: #{toRem(16)};
  }
}

.button {
  width: 100%;
  padding: var(--b-space-s) 0 var(--b-space-s) var(--b-space-xl);

  text-align: initial;

  [dir='rtl'] & {
    padding: var(--b-space-s) var(--b-space-xl) var(--b-space-s) 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: var(--b-space-s) 0;
}

.item {
  width: 100%;
}

.phoneLink {
  display: block;

  width: 100%;
  padding: var(--b-space-s) 0 var(--b-space-s) var(--b-space-xl);

  text-align: initial;
  text-decoration: none;

  [dir='rtl'] & {
    padding: var(--b-space-s) var(--b-space-xl) var(--b-space-s) 0;
  }
}
