$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/functions';
@import 'src/ui/styles/tools/mixins/common';

.tooltip {
  @include font-s-300;
  z-index: var(--b-z-index-5);

  border-radius: #{toRem(4)};
  padding: var(--b-space-xs);

  color: var(--b-color-text-light);
  background: var(--b-color-tooltip-background);
}

.closeButton {
  @include hideOnNonTactileDevices;

  color: var(--b-color-text-light);
}
