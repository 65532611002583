$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mixins/containers';
@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.footer {
  --footer-padding: var(--b-space-l);

  border-top: #{toRem(1)} solid var(--b-color-fill-neutral-02);
  padding-top: var(--footer-padding);
  padding-bottom: var(--footer-padding);

  background-color: var(--b-color-bg-light);
}

.content {
  @include contentContainer;

  display: flex;
  justify-content: center;

  @media #{$fromLaptop} {
    justify-content: flex-start;
  }
}

.menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.menuItem {
  position: relative;

  padding-left: var(--b-space-s);
  padding-right: var(--b-space-s);

  &:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      margin: auto 0;
      width: #{toRem(1)};
      height: #{toRem(12)};

      background-color: var(--b-color-fill-neutral-03);

      [dir='rtl'] & {
        right: unset;
        left: 0;
      }
    }
  }
}
