$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';.button {
  width: 100%;
  padding: var(--b-space-s) 0;

  text-align: initial;
}

.container {
  margin: var(--b-space-xxs) 0;
}
