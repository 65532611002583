$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools/mediaQueries';
@import 'src/ui/styles/tools/functions';

.dropdown {
  z-index: var(--b-z-index-2);
  top: #{toRem(24)};
  right: #{toRem(16)};
  left: #{toRem(16)};

  padding: unset;

  @media #{$fromLaptop} {
    top: #{toRem(16)};
    right: #{toRem(0)};
    left: unset;

    width: #{toRem(450)};

    [dir='rtl'] & {
      right: unset;
      left: #{toRem(0)};
    }
  }
}

.unauthenticatedDropdown {
  @media #{$fromLaptop} {
    top: #{toRem(8)};
  }
}

.authenticatedHeader {
  display: flex;
  justify-content: space-between;

  border-bottom: var(--b-color-fill-neutral-02) #{toRem(1)} solid;
  padding: var(--b-space-l);

  @media #{$fromLaptop} {
    padding: var(--b-space-xl) var(--b-space-xl) var(--b-space-l);
  }
}

.unauthenticatedHeader {
  margin: var(--b-space-l);
  border-bottom: var(--b-color-fill-neutral-02) #{toRem(1)} solid;
  padding-bottom: var(--b-space-m);
}

.listButton {
  display: flex;

  width: 100%;
  padding: var(--b-space-s) var(--b-space-l);

  &:hover {
    cursor: pointer;

    background-color: var(--b-color-interactive-primary-selected);
  }

  &:active {
    background-color: var(--b-color-interactive-primary-selected);
  }
}

.register {
  text-decoration: unset;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;

  padding: var(--b-space-l) 0;

  @media #{$fromLaptop} {
    padding: var(--b-space-xl) 0;
  }
}

.registeredMessageWrapper {
  padding: var(--b-space-l);
  padding-top: unset;
}
