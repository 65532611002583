$IMAGES_BASE_URL:'https://s7g10.scene7.com/is/image/barcelo/';@import 'src/ui/styles/tools';

.alertInlineWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.alertInline {
  border-radius: #{toRem(8)};
  width: 100%;
  padding: var(--b-space-xs) var(--b-space-s);

  @include font-s-500;
}

.info {
  background-color: var(--b-color-support-info-light);
}

.warning {
  background-color: var(--b-color-support-warning-light);
}

.success {
  background-color: var(--b-color-support-success-light);
  color: var(--b-color-support-success-dark);
}

.error {
  background-color: var(--b-color-support-error-light);
}

.neutral {
  background-color: var(--b-color-fill-neutral-01);
}
